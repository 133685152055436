// Not so sure about this concept. .cart-item is shared across mini overlay and
// various ecom screens. Make sure you're aware of the side effects from
// changes in this file.
@import './../../../../scss/theme-bootstrap';

.cart-item {
  display: flex;
  width: 100%;
  text-align: left;
  color: $color-black;
  padding: 10px 0;
  border-bottom: 1px solid $color-gray;
  &:hover {
    color: $color-black;
  }
  // Layout:
  &__img,
  &__info,
  &__cart-details {
    display: block;
  }
  &__img-container {
    width: 16.5%;
    min-height: 1px;
    margin-right: 15px;
  }
  &__img {
    min-height: 1px;
    margin-right: 4.25%;
    width: 100%;
  }
  &__info {
    width: 82.5%;
  }
  &__cart-details {
    width: 22.25%;
    text-align: right;
  }
  &__subheading {
    display: block;
    font-size: 15px;
  }
  &__subline {
    display: block;
  }
  &__sku-label,
  &__qty {
    font-size: 11px;
    margin: 5px 0;
  }
  &__qty {
    text-transform: uppercase;
    margin: 0.5em 0 1em;
  }
  &__price {
    font-size: 1.2em;
    letter-spacing: 0;
    color: $color-black;
  }
  &__shade {
    @include pie-clearfix;
    margin: 4px 1px 8px;
    display: flex;
    margin-bottom: 10px;
    &__swatch {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
    }
    &__name {
      display: inline-block;
      margin: 0;
      float: left;
      font-size: 11px;
    }
  }
}

.shared-cart-item {
  &__info-header {
    @include pie-clearfix;
  }
}

/*
 * Kit specific sub-items.
 */
.kit-item {
  &__sub-items {
    &__item {
      clear: both; // largely for PG display.
    }

    .kit-subitem {
      display: flex;
      flex-direction: row;
      padding: 3px 0;
      .cart-item {
        padding: 0;
        // This is the drupal FE version of cart--item__product-name.
        // eyeroll
        &__subheading {
          font-size: 13px;
        }
      }
      .shared-cart-item {
        &__thumb {
          @include ltr-rule(margin-right, 2%);
          float: none;
          width: 15%;
          max-width: 45px;
        }
        &__info {
          flex: 1;
          font-size: 11px;
          float: none;
        }
      }
    }

    // Hide some items.
    .cart-item {
      &__qty,
      &__price,
      &__sku-price,
      &__shade__price {
        display: none;
      }
    }
  }
}
